  // This file is automatically compiled by Webpack, along with any other files
  // present in this directory. You're encouraged to place your actual application logic in
  // a relevant structure within app/javascript and only use these pack files to reference
  // that code so it'll be compiled.

  //= require algolia/v3/algoliasearch.min
  //= require algolia/typeahead.jquery
  //= require hogan

  require("@rails/ujs").start()
  require("turbolinks").start()
  require("@rails/activestorage").start()
  require("channels")
  require('jquery')
  //const flatpickr = require("flatpickr");
  // Uncommenti to copy all static images under ../images to the output folder and reference
  // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
  // or the `imagePath` JavaScript helper below.
  //
  // const images = require.context('../images', true)
  // const imagePath = (name) => images(name, true)
  import "@fortawesome/fontawesome-free/js/all";
  import 'bootstrap';
  import "bootstrap-select";
  import "../stylesheets/application";
  import "../stylesheets/login";
  import "../stylesheets/global";
  import flatpickr from 'flatpickr';
  import { Spanish } from "flatpickr/dist/l10n/es.js";
  import $ from 'jquery';
  //import 'bootstrap-datepicker';


  // $(document).on('turbolinks:load', function() {
  // alert("NEXT");

  // });

  $(document).ready(function() {
      $("#show_hide_password a").on('click', function(event) {
          event.preventDefault();
          if($('#show_hide_password input').attr("type") == "text"){
              $('#show_hide_password input').attr('type', 'password');
              $('#show_hide_password svg').addClass( "fa-eye-slash" );
              $('#show_hide_password svg').removeClass( "fa-eye" );
          }else if($('#show_hide_password input').attr("type") == "password"){
              $('#show_hide_password input').attr('type', 'text');
              $('#show_hide_password svg').removeClass( "fa-eye-slash" );
              $('#show_hide_password svg').addClass( "fa-eye" );
          }
      });
  });


    // url = "/home/state_options?parent_state=#{cat}"
    // $.ajax
    //   type: 'GET'
    //   url: url
    //   success: (data) ->
    //     select_wrapper.attr('disabled', false)
    //     select_wrapper.html(data)
    //   error: (error) ->
    //     alert 'Por favor intente nuevamente: ' + error



  $(document).on('turbolinks:load', function() {
      //these methods are called it on load
      components_ready();
      expedient_ready();
      siniestro_juridico(); 
      filtro_consultoria_juridica_ready();
      isearch_ready();

      $("#expedient_main_category_id").change(expedient_ready);
      $("#expedient_tipo_persona").change(siniestro_juridico);
      $("#expedient_busq_categoria").change(search_ready);
      $("#q").change(isearch_ready);

      $("#expedient_creado_por").selectpicker(
        {
          liveSearch: true,
          liveSearchPlaceholder: "Buscar por nombre, apellido, rol o email",
          noneResultsText: 'No se encontraron resultados con la búsqueda {0}'
        }
      );

      $("#expedient_revisado_por").selectpicker(
        {              
          liveSearch: true,
          liveSearchPlaceholder: "Buscar por nombre, apellido, rol o email",
          noneResultsText: 'No se encontraron resultados con la búsqueda {0}'
        }
      );


      // PLACEHOLDER INPUT DROPDOWN REASIGNAR
      $('.filter-option-inner-inner').css("color", "black")

      // ICONO DE BÚSQUEDA PLACEHOLDER INPUT DROPDOWN REASIGNAR
      // $(".bs-searchbox").append(
      //   `<span 
      //   class="fa fa-search"
      //   style="
      //   float: right;
      //   margin-right: 20px;
      //   margin-top: -30px;
      //   position: relative;
      //   z-index: 2;
      // "
      // >
      // </span>`);

      // $(".bs-searchbox input").on("keyup", function() {
      //   if (this.value.length !== 0) {
      //     $(".bs-searchbox svg").hide()
      //   }
      //   else {
      //     $(".bs-searchbox svg").show()
      //   }
      // })

      // $(".bs-searchbox input").on("change", function() {
      //   $(".bs-searchbox svg").show()
      // })

      // $(".bs-searchbox").on("change", function() {
      //   if ($(".bs-searchbox svg"))
      //     $(".bs-searchbox svg").show()
      //   else
      //   $(".bs-searchbox svg").show()
      // })

      // $(".bs-searchbox svg").on("change", function() {
      //   if ($(".bs-searchbox svg"))
      //     $(".bs-searchbox svg").show()
      //   else
      //   $(".bs-searchbox svg").show()
      // })

  });

  function components_ready() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $(".datepicker").flatpickr(
        {
            altInput: true,
            altFormat: "F j, Y",
            "locale": Spanish,
        }
      );
  };


  function expedient_ready() {

    if ($("#expedient_main_category_id option:selected" ).text() == "Operaciones Pólizas" || $("#expedient_main_category_id option:selected" ).text() == "Operaciones Cotizaciones" || $("#expedient_main_category_id option:selected" ).text() == "Operaciones Siniestro" || $("#expedient_main_category_id option:selected" ).text() == "Operaciones Telemercadeo" || $("#expedient_main_category_id option:selected" ).text() == "Operaciones Clientes Fianzas") {
      $('.tiene_subtipo').show();
    } else {
      $('.tiene_subtipo').hide();
      $('#expedient_subtipo').val('');      
    }

    if ($("#expedient_main_category_id option:selected" ).text() == "Operaciones Pólizas" || $("#expedient_main_category_id option:selected" ).text() == "Operaciones Cotizaciones" || $("#expedient_main_category_id option:selected" ).text() == "Operaciones Polizas Fianzas" ){
      $('.tiene_tomador').show();
    }else {
      $('.tiene_tomador').hide();
      $('#expedient_identificador_tomador').val(''); 
    }

    if ($("#expedient_main_category_id option:selected" ).text() == "Capital Humano") {

      if ($("#expedient_tipo_persona option[value='Juridica']").length != 0){
        $("#expedient_tipo_persona option[value='Juridica']").remove();
      }
      if ($("#expedient_tipo_persona option[value='Natural']").length == 0){
        $("#expedient_tipo_persona").append('<option value="Natural">Persona Natural</option>');
      }
    
      $("#expedient_tipo_persona option[value='Juridica']").remove();
    
    }else  if ($("#expedient_main_category_id option:selected" ).text() == "Reaseguros") {

      if ($("#expedient_tipo_persona option[value='Juridica']").length == 0){
        $("#expedient_tipo_persona").append('<option value="Juridica">Persona Jurídica</option>');
      }
      if ($("#expedient_tipo_persona option[value='Natural']").length != 0){
        $("#expedient_tipo_persona option[value='Natural']").remove();
      }
    }else { 
      if ($("#expedient_tipo_persona option[value='Juridica']").length == 0){
        $("#expedient_tipo_persona").append('<option value="Juridica">Persona Jurídica</option>');
      }
      if ($("#expedient_tipo_persona option[value='Natural']").length == 0){
        $("#expedient_tipo_persona").append('<option value="Natural">Persona Natural</option>');
      }
 
    }

    if ($("#expedient_main_category_id option:selected" ).text() == "Operaciones Siniestro") {
      
      $("#expedient_subtipo option[value='Colectivo']").remove();
      $("#expedient_subtipo option[value='Personas']").remove();

      $("#expedient_subtipo option[value='Contragarante']").remove(); 
      $("#expedient_subtipo option[value='Titular']").remove(); 

      if ($("#expedient_subtipo option[value='Accidentes Personales']").length == 0){
          $("#expedient_subtipo").append('<option value="Accidentes Personales">Accidentes Personales</option>');
        }
  
      if ($("#expedient_subtipo option[value='Funerario']").length == 0){
          $("#expedient_subtipo").append('<option value="Funerario">Funerario</option>');
        }
  
      if ($("#expedient_subtipo option[value='Salud']").length == 0){
          $("#expedient_subtipo").append('<option value="Salud">Salud</option>');
        }
  
      if ($("#expedient_subtipo option[value='Vida']").length == 0){
          $("#expedient_subtipo").append('<option value="Vida">Vida</option>');
        }  
      }
    else if ($("#expedient_main_category_id option:selected" ).text() == "Operaciones Clientes Fianzas") {

      if ($("#expedient_subtipo option[value='Contragarante']").length == 0){
        $("#expedient_subtipo").append('<option value="Contragarante">Contragarante</option>');
      }

      if ($("#expedient_subtipo option[value='Titular']").length == 0){
        $("#expedient_subtipo").append('<option value="Titular">Titular</option>');
      }
      
      $("#expedient_subtipo option[value='Colectivo']").remove();
      $("#expedient_subtipo option[value='Personas']").remove();
      $("#expedient_subtipo option[value='Auto']").remove();
      $("#expedient_subtipo option[value='Patrimoniales']").remove();

      }
    else{
         $("#expedient_subtipo option[value='Vida']").remove();
         $("#expedient_subtipo option[value='Accidentes Personales']").remove();
         $("#expedient_subtipo option[value='Funerario']").remove();
         $("#expedient_subtipo option[value='Salud']").remove();
         $("#expedient_subtipo option[value='Contragarante']").remove(); 
         $("#expedient_subtipo option[value='Titular']").remove(); 

      
      if ($("#expedient_subtipo option[value='Colectivo']").length == 0){
        $("#expedient_subtipo").append('<option value="Colectivo">Colectivo</option>');
      }

      if ($("#expedient_subtipo option[value='Personas']").length == 0){
        $("#expedient_subtipo").append('<option value="Personas">Personas</option>');
      }
    }

    // Segmento de consultoria juridica
    if ($("#expedient_main_category_id option:selected" ).text() == "Consultoría Jurídica") {

      if ( ($("#expedient_tipo_persona option[value='Juridica']").length == 0) ) {
        $("#expedient_tipo_persona").append('<option value="Juridica">Persona Jurídica</option>');  
      }
 
      if ( ($("#expedient_tipo_persona option[value='Natural']").length != 0) ) {
        $("#expedient_tipo_persona option[value='Natural']").remove();  
      }
      

      document.getElementById('expedient_tipo_persona').value = 'Juridica';
      // $('#expedient_tipo_persona').attr('readonly', 'true');
      $('.tiene_identificador_unico').hide();
      $('.expedient_identificador_unico').hide();
      $('.expedient_email_usuario').hide();
      $('.expedient_email_verification').hide();
      $('.tiene_subtipo').show();
      
 
      // En la creacion del expediente, se escogera el subtipo
      var select_wrapper = $('#expedient_subtipo');
      var main_category_category_id = document.getElementById('expedient_main_category_id').value;
      var url = "/home/subtype_options?main_category_id="+main_category_category_id
      console.log("consul jurid")
      $.ajax({
        type: 'GET',
        url: url,
        success: function(data) {
          select_wrapper.attr('disabled', false);
          return select_wrapper.html(data);
        },
        error: function(error) {
          return alert('Por favor intente nuevamente: ' + error);
        }
      });

      // 
      $('.subcategory_n2').hide()
      $('.subcategory_n3').hide()
      $('.subcategory_n4').hide()
      $('.subcategory_n5').hide()
      $('.subcategory_n6').hide()
      $('.subcategory_n7').hide()

      // Se utilizaran este segmento en la edicion de un expediente que es donde se determinara que subcategoria tendra el expediente
      $('#expedient_subcategory_id_n1').on("change", function()
      {    
        var select_wrapper = $('#expedient_subcategory_id_n2');
        if ( document.getElementById('expedient_subcategory_id_n1').value != '' ) {
          var father_subcategory_id = document.getElementById('expedient_subcategory_id_n1').value;
          var url = "/home/subcategory_options_consultoria_juridica?father_subcategory_id="+father_subcategory_id
          console.log("n1")
          $.ajax({
            type: 'GET',
            url: url,
            success: function(data) {

              console.log(data)
              if ( data.length == 39 ) {
                console.log("hiding 2-3-4-5-6-7")
                $('.subcategory_n2').hide()
                $('.subcategory_n3').hide()
                $('.subcategory_n4').hide()
                $('.subcategory_n5').hide()
                $('.subcategory_n6').hide()
                $('.subcategory_n7').hide()
              } else {
                $('.subcategory_n2').show()
              }

              select_wrapper.attr('disabled', false);
              return select_wrapper.html(data);

            },
            error: function(error) {
              return alert('Por favor intente nuevamente: ' + error);
            }
          });  
        }

      });

      $('#expedient_subcategory_id_n2').on("change", function()
      {    
        var select_wrapper = $('#expedient_subcategory_id_n3');
        if ( document.getElementById('expedient_subcategory_id_n2').value != '' ) {
          var father_subcategory_id = document.getElementById('expedient_subcategory_id_n2').value;
          var url = "/home/subcategory_options_consultoria_juridica?father_subcategory_id="+father_subcategory_id
          console.log("n2")
          $.ajax({
            type: 'GET',
            url: url,
            success: function(data) {

              console.log(data)
              if ( data.length == 39 ) {
                console.log("hiding 3-4-5-6-7")
                $('.subcategory_n3').hide()
                $('.subcategory_n4').hide()
                $('.subcategory_n5').hide()
                $('.subcategory_n6').hide()
                $('.subcategory_n7').hide()
              } else {
                $('.subcategory_n3').show()
                $('.subcategory_n4').hide()
                $('.subcategory_n5').hide()
                $('.subcategory_n6').hide()
                $('.subcategory_n7').hide()
              }

              select_wrapper.attr('disabled', false);
              return select_wrapper.html(data);

            },
            error: function(error) {
              return alert('Por favor intente nuevamente: ' + error);
            }
          });  
        }

      });

      $('#expedient_subcategory_id_n3').on("change", function()
      {    
        var select_wrapper = $('#expedient_subcategory_id_n4');
        if ( document.getElementById('expedient_subcategory_id_n3').value != '' ) {
          var father_subcategory_id = document.getElementById('expedient_subcategory_id_n3').value;
          var url = "/home/subcategory_options_consultoria_juridica?father_subcategory_id="+father_subcategory_id
          console.log("n3")
          $.ajax({
            type: 'GET',
            url: url,
            success: function(data) {
 
              console.log(data)
              if ( data.length == 39 ) {
                console.log("hiding 4-5-6-7")
                $('.subcategory_n4').hide()
                $('.subcategory_n5').hide()
                $('.subcategory_n6').hide()
                $('.subcategory_n7').hide()
              } else {
                $('.subcategory_n4').show()
                $('.subcategory_n5').hide()
                $('.subcategory_n6').hide()
                $('.subcategory_n7').hide()
              }

              select_wrapper.attr('disabled', false);
              return select_wrapper.html(data);

            },
            error: function(error) {
              return alert('Por favor intente nuevamente: ' + error);
            }
          });  
        }

      });

      $('#expedient_subcategory_id_n4').on("change", function()
      {    
        var select_wrapper = $('#expedient_subcategory_id_n5');
        if ( document.getElementById('expedient_subcategory_id_n4').value != '' ) {
          var father_subcategory_id = document.getElementById('expedient_subcategory_id_n4').value;
          var url = "/home/subcategory_options_consultoria_juridica?father_subcategory_id="+father_subcategory_id
          console.log("n4")
          $.ajax({
            type: 'GET',
            url: url,
            success: function(data) {

              console.log(data)
              if ( data.length == 39 ) {
                console.log("hiding 5-6-7")
                $('.subcategory_n5').hide()
                $('.subcategory_n6').hide()
                $('.subcategory_n7').hide()
              } else {
                $('.subcategory_n5').show()
                $('.subcategory_n6').hide()
                $('.subcategory_n7').hide()
              }

              select_wrapper.attr('disabled', false);
              return select_wrapper.html(data);

            },
            error: function(error) {
              return alert('Por favor intente nuevamente: ' + error);
            }
          });  
        }

      });

      $('#expedient_subcategory_id_n5').on("change", function()
      {    
        var select_wrapper = $('#expedient_subcategory_id_n6');
        if ( document.getElementById('expedient_subcategory_id_n5').value != '' ) {
          var father_subcategory_id = document.getElementById('expedient_subcategory_id_n5').value;
          var url = "/home/subcategory_options_consultoria_juridica?father_subcategory_id="+father_subcategory_id
          console.log("n5")
          $.ajax({
            type: 'GET',
            url: url,
            success: function(data) {

              console.log(data)
              if ( data.length == 39 ) {
                console.log("hiding 6-7")
                $('.subcategory_n6').hide()
                $('.subcategory_n7').hide()
              } else {
                $('.subcategory_n6').show()
                $('.subcategory_n7').hide()
              }

              select_wrapper.attr('disabled', false);
              return select_wrapper.html(data);

            },
            error: function(error) {
              return alert('Por favor intente nuevamente: ' + error);
            }
          });  
        }

      });

      $('#expedient_subcategory_id_n6').on("change", function()
      {    
        var select_wrapper = $('#expedient_subcategory_id_n7');
        if ( document.getElementById('expedient_subcategory_id_n6').value != '' ) {
          var father_subcategory_id = document.getElementById('expedient_subcategory_id_n6').value;
          var url = "/home/subcategory_options_consultoria_juridica?father_subcategory_id="+father_subcategory_id
          console.log("n6")
          $.ajax({
            type: 'GET',
            url: url,
            success: function(data) {

              console.log(data)
              if ( data.length == 39 ) {
                console.log("hiding 7")
                $('.subcategory_n7').hide()
              } else {
                $('.subcategory_n7').show()
              }

              select_wrapper.attr('disabled', false);
              return select_wrapper.html(data);

            },
            error: function(error) {
              return alert('Por favor intente nuevamente: ' + error);
            }
          });  
        }

      });


      //Se utilizara este segmento tambien en la edicion pero una vez que el expediente ya tenga subcategoria y se tenga que poblar los listados de subcategorias por niveles

      
      
  
    } else {
      // Arroja error en la consola del navegador cuando no se tiene acceso a este id, por ello se valida si es null
      if ( document.getElementById('expedient_tipo_persona') != null) {
        document.getElementById('expedient_tipo_persona').disabled = false; 
      } 

      console.log("not consultoria juridica")
      // if ( document.getElementById('expedient_main_category_id') != null) {
      //   var select_wrapper = $('#expedient_subtipo');
      //   var main_category_category_id = document.getElementById('expedient_main_category_id').value;
      //   var url = "/home/subtype_options?main_category_id="+main_category_category_id

      //   $.ajax({
      //     type: 'GET',
      //     url: url,
      //     success: function(data) {
      //       select_wrapper.attr('disabled', false);
      //       return select_wrapper.html(data);
      //     },
      //     error: function(error) {
      //       return alert('Por favor intente nuevamente: ' + error);
      //     }
      //   }); 


      // }
      
    }

    // if ($("#expedient_main_category_id option:selected" ).text() == "Consultoría Jurídica") {
    //   alert("hey");
    //   $("#expedient_tipo_persona option[value='Juridica']").remove();
    
    // }else if ($("#expedient_tipo_persona option[value='Juridica']").length == 0){
    
    //   $("#expedient_tipo_persona").append('<option value="Juridica">Persona Jurídica</option>');
    // }
  }

  function filtro_consultoria_juridica_ready() {

  }
  function isearch_ready(){
  }

  function siniestro_juridico (){

      if ($("#expedient_main_category_id option:selected" ).text() == "Operaciones Siniestro"){

        if ($("#expedient_tipo_persona option:selected").val() == "Juridica"){
          $("#expedient_subtipo option[value='Accidentes Personales']").remove();
          $("#expedient_subtipo option[value='Funerario']").remove();
          $("#expedient_subtipo option[value='Salud']").remove();
          $("#expedient_subtipo option[value='Vida']").remove(); 

            
        }else{

          if ($("#expedient_subtipo option[value='Accidentes Personales']").length == 0){
              $("#expedient_subtipo").append('<option value="Accidentes Personales">Accidentes Personales</option>');
            }

          if ($("#expedient_subtipo option[value='Funerario']").length == 0){
              $("#expedient_subtipo").append('<option value="Funerario">Funerario</option>');
            }

          if ($("#expedient_subtipo option[value='Salud']").length == 0){
              $("#expedient_subtipo").append('<option value="Salud">Salud</option>');
            }

          if ($("#expedient_subtipo option[value='Vida']").length == 0){
              $("#expedient_subtipo").append('<option value="Vida">Vida</option>');
            }  
          if ($("#expedient_subtipo option[value='Contragarante']").length == 0){
              $("#expedient_subtipo").append('<option value="Contragarante">Contragarante</option>');
            } 
          if ($("#expedient_subtipo option[value='Titular']").length == 0){
              $("#expedient_subtipo").append('<option value="Titular">Titular</option>');
            }   
        }
      }
    }

  function search_ready(){
    $('#expedient_busq_subcategoria').val('');
    var select_wrapper = $('#expedient_busq_subcategoria');
    var category = $(this).val();
    var url = "/home/subcategory_options?subcategory="+category
    $.ajax({
      type: 'GET',
      url: url,
      success: function(data) {
        select_wrapper.attr('disabled', false);
        return select_wrapper.html(data);
      },
      error: function(error) {
        return alert('Por favor intente nuevamente: ' + error);
      }
    });


  }



  addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail
    target.insertAdjacentHTML("beforebegin", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename">${file.name}</span>
      </div>
    `)
  })

  addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
  })

  addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  })

  addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
  })

  addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
  })





